import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  FloatingLabel,
  Stack,
  Spinner,
} from "react-bootstrap";
import {
  CheckCircle,
  CheckCircleFill,
  Eye,
  EyeSlash,
} from "react-bootstrap-icons";

function RegisterPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  // Track the password (first field) and whether it's shown in clear text
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // Track the confirm password (second field)
  const [confirmPassword, setConfirmPassword] = useState("");

  // Basic password checks for “checklist”
  const meetsLength = password.length >= 8;
  const meetsDigit = /[0-9]/.test(password);
  const meetsSpecial = /[^A-Za-z0-9]/.test(password);

  const handleRegister = (e) => {
    const form = e.currentTarget;

    // Validate the form
    if (!form.checkValidity()) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      // If valid, proceed (simulate API call)
      e.preventDefault();
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        // ... handle success here ...
      }, 1500);
    }
    setValidated(true);
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md={6} className="px-4">
          <Stack gap={4}>
            <div>
              <h4 className="mb-2">Het avontuur begint hier 🚀</h4>
              <p className="mb-0 text-body-secondary">
                Ervaar hoe leuk en eenvoudig leren kan zijn!
              </p>
            </div>

            {/*
              noValidate + validated => native RB validation
              We'll override .is-valid so we don't get green border 
              (if you add the custom CSS to remove the green border).
            */}
            <Form
              noValidate
              validated={validated}
              autoComplete="on"
              onSubmit={handleRegister}
            >
              <Stack gap={4}>
                {/* ==================== E-mail ==================== */}
                <Form.Group controlId="floatingInput">
                  <FloatingLabel label="E-mailadres">
                    <Form.Control
                      required
                      type="email"
                      placeholder="naam@voorbeeld.nl"
                      disabled={isLoading}
                    />
                    <Form.Control.Feedback type="invalid">
                      Geen geldig e-mailadres ingevuld.
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>

                {/* ============ First Password Field ============ */}
                <Form.Group controlId="floatingPassword">
                  <FloatingLabel
                    label="Wachtwoord"
                    className="position-relative"
                  >
                    <Form.Control
                      required
                      // Use a pattern to require at least 8 chars and 1 special char
                      pattern="^(?=.*[^A-Za-z0-9]).{8,}$"
                      type={showPassword ? "text" : "password"}
                      placeholder="Wachtwoord"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={isLoading}
                      name="password"
                      autoComplete="new-password"
                      aria-describedby="toon-wachtwoord"
                    />
                    {/* Toggle show/hide password with Eye / EyeSlash icons */}
                    <Button
                      id="toon-wachtwoord"
                      variant="outline-gray"
                      disabled={isLoading}
                      className="p-2 border-0 text-body me-3 rounded-pill d-flex align-items-center position-absolute end-0 top-50 translate-middle-y "
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <EyeSlash /> : <Eye />}
                    </Button>
                    {/* <Form.Control.Feedback type="invalid">
                        Een wachtwoord moet minimaal uit 8 karakters bestaan en
                        ten minste één speciaal teken.
                      </Form.Control.Feedback> */}
                  </FloatingLabel>

                  {/* Password Criteria Checklist */}
                  <Form.Text className="text-muted mt-1 d-block">
                    <div
                      className={`d-flex align-items-center gap-1 ${
                        meetsLength ? "text-success" : ""
                      }`}
                    >
                      {meetsLength ? <CheckCircleFill /> : <CheckCircle />}
                      Minimaal 8 tekens
                    </div>
                    <div
                      className={`d-flex align-items-center gap-1 ${
                        meetsDigit ? "text-success" : ""
                      }`}
                    >
                      {meetsDigit ? <CheckCircleFill /> : <CheckCircle />}
                      Ten minste één cijfer
                    </div>
                    <div
                      className={`d-flex align-items-center gap-1 ${
                        meetsSpecial ? "text-success" : ""
                      }`}
                    >
                      {meetsSpecial ? <CheckCircleFill /> : <CheckCircle />}
                      Ten minste één speciaal teken
                    </div>
                  </Form.Text>
                </Form.Group>

                {/* ========== Confirm Password Field ========== */}
                <Form.Group controlId="floatingPasswordConfirm">
                  <FloatingLabel label="Wachtwoord herhalen">
                    <Form.Control
                      required
                      type="password"
                      placeholder="Wachtwoord herhalen"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      disabled={isLoading}
                      autoComplete="new-password"
                    />
                    <Form.Control.Feedback type="invalid">
                      Vul alstublieft het wachtwoord nogmaals in.
                    </Form.Control.Feedback>

                    <Form.Text className="text-muted">
                      Herhaal wachtwoord (ter controle)
                    </Form.Text>
                  </FloatingLabel>
                </Form.Group>

                {/* 
                  If you want to ensure password === confirmPassword,
                  you can do a custom check in handleRegister or
                  set a custom validity in onChange.
                */}

                {/* ========== Terms & Conditions Checkbox ========== */}
                <Form.Group controlId="formBasicCheckbox">
                  <div className="d-flex gap-2">
                    <Form.Check
                      required
                      type="checkbox"
                      label="Ik accepteer de"
                      disabled={isLoading}
                      feedback="Je moet de voorwaarden accepteren voordat je kunt registreren."
                      feedbackType="invalid"
                    />
                    <Link
                      to="/forgot-password"
                      className="link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                    >
                      Gebruiksvoorwaarden en Privacyverklaring
                    </Link>
                  </div>
                </Form.Group>

                {/* ========== Submit Button ========== */}
                <Button
                  variant="primary"
                  type="submit"
                  size="lg"
                  className="w-100 rounded-2"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner animation="border" role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>{" "}
                      Bezig met registreren...
                    </>
                  ) : (
                    "Maak account aan"
                  )}
                </Button>
              </Stack>
            </Form>

            {/* Already have an account? */}
            <div className="d-flex align-item-center justify-content-center flex-wrap gap-2">
              <p className="mb-0 text-body-secondary">Heb je al een account?</p>
              <Link
                className="link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                to="/login"
              >
                Login met je account
              </Link>
            </div>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
}

export default RegisterPage;
