import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  FloatingLabel,
  Stack,
  Spinner,
} from "react-bootstrap";

function ForgotPasswordPage() {
  // A state to manage the loading status of the login button
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    // Set the loading state to true
    setIsLoading(true);

    // Mimic an API call by disabling the button and showing the spinner for, e.g., 3 seconds
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md={6} className="px-4">
          <Stack gap={4}>
            <div>
              <h4 className="mb-2">Wachtwoord vergeten 🔒</h4>
              <p className="mb-0 text-body-secondary">
                Vul je e-mailadres in en we sturen je instructies om je
                wachtwoord opnieuw in te stellen.
              </p>
            </div>
            <Form onSubmit={handleLogin}>
              <Stack gap={4}>
                <FloatingLabel controlId="floatingInput" label="E-mailadres">
                  <Form.Control
                    type="email"
                    placeholder="naam@voorbeeld.nl"
                    disabled={isLoading}
                  />
                </FloatingLabel>

                <Button
                  variant="primary"
                  type="submit"
                  size="lg"
                  className="w-100 rounded-2"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner animation="border" role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>{" "}
                      Wachtwoord aan het resetten...
                    </>
                  ) : (
                    "Stuur resetlink"
                  )}
                </Button>
              </Stack>
            </Form>
            <div className="d-flex align-item-center justify-content-center flex-wrap gap-2">
              <Link
                className="link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                to="/login"
              >
                Terug naar login
              </Link>
            </div>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPasswordPage;
