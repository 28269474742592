import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  FloatingLabel,
  Stack,
  Spinner,
} from "react-bootstrap";

function LoginPage() {
  // A state to manage the loading status of the login button
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    // Set the loading state to true
    setIsLoading(true);

    // Mimic an API call by disabling the button and showing the spinner for, e.g., 3 seconds
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md={6} className="px-4">
          <Stack gap={4}>
            <div>
              <h4 className="mb-2">Welkom bij Newlearn LMS! 👋🏻</h4>
              <p className="mb-0 text-body-secondary">
                Login met je e-mailadres en wachtwoord om verder te gaan.
              </p>
            </div>
            <Form onSubmit={handleLogin}>
              <Stack gap={4}>
                <FloatingLabel controlId="floatingInput" label="E-mailadres">
                  <Form.Control
                    type="email"
                    placeholder="naam@voorbeeld.nl"
                    disabled={isLoading}
                  />
                </FloatingLabel>

                <FloatingLabel controlId="floatingPassword" label="Wachtwoord">
                  <Form.Control
                    type="password"
                    placeholder="Wachtwoord"
                    disabled={isLoading}
                  />
                </FloatingLabel>

                <div className="d-flex justify-content-between align-items-center">
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Ingelogd blijven"
                      disabled={isLoading}
                    />
                  </Form.Group>

                  <Link
                    to="/forgot-password"
                    className="small link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                  >
                    Wachtwoord vergeten?
                  </Link>
                </div>

                <Button
                  variant="primary"
                  type="submit"
                  size="lg"
                  className="w-100 rounded-2"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner animation="border" role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>{" "}
                      Bezig met inloggen...
                    </>
                  ) : (
                    "Login"
                  )}
                </Button>
              </Stack>
            </Form>
            <div className="d-flex align-item-center justify-content-center flex-wrap gap-2">
              <p className="mb-0 text-body-secondary">Nieuw op ons platform?</p>
              <Link
                className="link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                to="/register"
              >
                Maak een account aan
              </Link>
            </div>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginPage;
